
import React, { Component } from "react";
import queryString from 'query-string'
import { Link } from "react-router-dom";
import { resendActivationEmail } from "../../actions/authActions";
import Localization from "../localization/localization";


class EmailActivationMessage extends Component {

  constructor() {
    super();
    this.state = {
      userid: "",
      error: ""
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  componentDidMount() {
    const usuarioParsed = queryString.parse(this.props.location.search);
    this.setState({ userid: usuarioParsed.id });
  }

  sendActivationEmail = () => {

    let language = localStorage.getItem("exmi_language");
    if (language == null)
      language = "es";

    resendActivationEmail(this.state.userid, language)
      .then(res => console.log(res))
      .catch(err => {
        console.log(err);
        return null;
      });
  };

  render() {
    return (
      <div>
        <div className="col s12 m12 l8 container">
          <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
            <div>
              <h3 className="font-face-mb">
                {this.locale.auth_emailactivation_label_confirmation}
              </h3>
              <br />
              <br />
              <h4 className="font-face-mb">
                {this.locale.auth_emailactivation_label_thanks}
              </h4>
              <h5>
                {this.locale.auth_emailactivation_label_email_sent}
              </h5>
            <br/>
                <h6 className="font-face-mb">
                  {this.locale.auth_emailactivation_label_email_not_received}&nbsp;
                  <button
                    type="button"
                    className="link-button linkColor"
                    onClick={() => this.sendActivationEmail()}>
                    {this.locale.auth_emailactivation_button_resend}
                  </button>
                </h6>
                <br></br>
                <br></br>
              <div className="col s12 m12 l12 center-align">
                <Link
                  to="/login"
                  style={{
                    width: "200px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px"
                  }}
                  className="btn-large hoverable buttonBackgroundColor"
                >
                  {this.locale.auth_emailactivation_button_login}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmailActivationMessage;
