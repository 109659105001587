import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import { getCountries } from "../../actions/countriesActions";
import { getZipCode } from "../../actions/zipcodeActions";
import materialize from "materialize-css"

export const VisitanteInformation = (props) => {

    const [errorEmail, setErrorEmail] = useState('');
    const [errorNombre, setErrorNombre] = useState('');
    const [errorApellido, setErrorApellido] = useState('');
    const [errorNacimiento, setErrorNacimiento] = useState('');
    const [errorSexo, setErrorSexo] = useState('');
    const [errorCiudad, setErrorCiudad] = useState('');
    const [errorCodigoPostal, setErrorCodigoPostal] = useState('');
    const [errorCarrera, setErrorCarrera] = useState('');
    const [errorNombreEmpresa, setErrorNombreEmpresa] = useState('');
    const [errorNotas, setErrorNotas] = useState('');
    const [errorPais, setErrorPais] = useState('');
    const [countries, setCountries] = useState([]);


    useEffect(() => {

        getCountries()
            .then(response => {
                let countries = [];
                countries = response.data.map((country) => {
                    return <option key={country.iso2} value={country.iso2}>{country.name}</option>
                });

                setCountries(countries);
                materialize.AutoInit();
                materialize.updateTextFields();
            });
    }, []);


    const update = (e) => {
        props.update(e.target.id, e.target.value);
    };


    const submit = async () => {

        let valid = true;
        let language = localStorage.getItem("exmi_language");

        // Errors clean up
        setErrorEmail("");
        setErrorNombre("");
        setErrorApellido("");
        setErrorNacimiento("");
        setErrorSexo("");
        setErrorCiudad("");
        setErrorCodigoPostal("");
        setErrorCarrera("");
        setErrorPais("");
        setErrorCarrera("");
        setErrorNombreEmpresa("");
        setErrorNotas("");

        if (language === "es") {
            // Email checks
            if (Validator.isEmpty(props.form.email)) {
                setErrorEmail("Email es requerido");
                valid = false;
            } else if (!Validator.isEmail(props.form.email)) {
                setErrorEmail("Email es invalido");
                valid = false;
            }

            // Nombres
            if (Validator.isEmpty(props.form.nombres)) {
                setErrorNombre("Nombre es requerido");
                valid = false;
            }

            // Apellidos
            if (Validator.isEmpty(props.form.apellidos)) {
                setErrorApellido("Apellido es requerido");
                valid = false;
            }

            // Año de nacimiento
            if (Validator.isEmpty(props.form.nacimiento)) {
                setErrorNacimiento("Año de nacimiento es requerido");
                valid = false;
            }
            else if (!Validator.isInt(props.form.nacimiento)) {
                setErrorNacimiento("Año de nacimiento invalido");
                valid = false;
            }

            // Género
            if (Validator.isEmpty(props.form.sexo)) {
                setErrorSexo("Género es requerido");
                valid = false;
            }

            // Ciudad
            if (props.form.pais !== "MX" && props.form.pais !== "US") {
                if (Validator.isEmpty(props.form.ciudad)) {
                    setErrorCiudad("Ciudad es requerida");
                    valid = false;
                }
            }

            // Pais
            if (Validator.isEmpty(props.form.pais)) {
                setErrorPais("Pais es requerido");
                valid = false;
            }

            // Código Postal
            if (Validator.isEmpty(props.form.codigoPostal)) {
                setErrorCodigoPostal("Código Postal es requerido");
                valid = false;
            } else if (props.form.pais === "MX" || props.form.pais === "US") {

                if (!Validator.isInt(props.form.codigoPostal)) {
                    setErrorCodigoPostal("Código Postal invalido, debe ser numérico");
                    valid = false;
                }
                else {
                    await getZipCode(props.form.pais, props.form.codigoPostal)
                        .then(response => {

                            if (response.data === null) {
                                setErrorCodigoPostal("Código Postal invalido");
                                props.update("estado", "");
                                props.update("ciudad", "");
                                valid = false;
                            }
                            else {
                                props.update("estado", response.data.stateName);
                                props.update("ciudad", response.data.city);
                            }
                        });
                }
            }

            // Carrera
            if (Validator.isEmpty(props.form.carrera)) {
                setErrorCarrera("Profesión es requerido");
                valid = false;
            }

            // Nombre Empresa
            if (Validator.isEmpty(props.form.nombreEmpresa)) {
                setErrorNombreEmpresa("El nombre de empresa es requerido");
                valid = false;
            }

            // Motivo de la visita
            if (Validator.isEmpty(props.form.notas)) {
                setErrorNotas("El motivo de su visita al evento es requerido");
                valid = false;
            }
        }
        else {
            // Email checks
            if (Validator.isEmpty(props.form.email)) {
                setErrorEmail("Email is Required");
                valid = false;
            } else if (!Validator.isEmail(props.form.email)) {
                setErrorEmail("Email is Invalid");
                valid = false;
            }

            // Nombres
            if (Validator.isEmpty(props.form.nombres)) {
                setErrorNombre("First Name is Required");
                valid = false;
            }

            // Apellidos
            if (Validator.isEmpty(props.form.apellidos)) {
                setErrorApellido("Last Name is Required");
                valid = false;
            }

            // Año de nacimiento
            if (Validator.isEmpty(props.form.nacimiento)) {
                setErrorNacimiento("Year of Birth is Required");
                valid = false;
            }
            else if (!Validator.isInt(props.form.nacimiento)) {
                setErrorNacimiento("Year of Birth is Invalid");
                valid = false;
            }

            // Género
            if (Validator.isEmpty(props.form.sexo)) {
                setErrorSexo("Gender is required");
                valid = false;
            }

            // Ciudad
            if (props.form.pais !== "MX" && props.form.pais !== "US") {
                if (Validator.isEmpty(props.form.ciudad)) {
                    setErrorCiudad("City is Required");
                    valid = false;
                }
            }

            // Pais
            if (Validator.isEmpty(props.form.pais)) {
                setErrorPais("Country is Required");
                valid = false;
            }

            // Código Postal
            if (Validator.isEmpty(props.form.codigoPostal)) {
                setErrorCodigoPostal("Zip Code is Required");
                valid = false;
            } else if (props.form.pais === "MX" || props.form.pais === "US") {

                if (!Validator.isInt(props.form.codigoPostal)) {
                    setErrorCodigoPostal("Zip Code is Invalid, it must be numeric");
                    valid = false;
                }
                else {
                    await getZipCode(props.form.pais, props.form.codigoPostal)
                        .then(response => {

                            if (response.data === null) {
                                setErrorCodigoPostal("Zip Code is Invalid");
                                props.update("estado", "");
                                props.update("ciudad", "");
                                valid = false;
                            }
                            else {
                                props.update("estado", response.data.stateName);
                                props.update("ciudad", response.data.city);
                            }
                        });
                }
            }

            // Carrera
            if (Validator.isEmpty(props.form.carrera)) {
                setErrorCarrera("Career is required");
                valid = false;
            }

            
            // Nombre Empresa
            if (Validator.isEmpty(props.form.nombreEmpresa)) {
                setErrorNombreEmpresa("Name of your company is required");
                valid = false;
            }

            // Motivo de la visita
            if (Validator.isEmpty(props.form.notas)) {
                setErrorNotas("The reason to visit the event is required");
                valid = false;
            }
        }

        if (valid)
            props.submit();
    };

    return (
        <div>
            <div className="input-field col s12">
                <input
                    disabled
                    placeholder=""
                    onChange={update}
                    value={props.form.email}
                    error={errorEmail}
                    id="email"
                    type="email"
                     className={classnames("font-face-msb", {
                        invalid: errorEmail
                    })}
                />
                <label htmlFor="email" className="active">{props.locale.dashboard_comprador_label_mail}</label>
                <span className="red-text">{errorEmail}</span>
            </div>
            <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={props.form.nombres}
                    error={errorNombre}
                    id="nombres"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorNombre
                    })}
                />
                <label htmlFor="nombres">{props.locale.dashboard_comprador_label_name}</label>
                <span className="red-text">{errorNombre}</span>
            </div>
            <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={props.form.apellidos}
                    error={errorApellido}
                    id="apellidos"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorApellido
                    })}
                />
                <label htmlFor="apellidos">{props.locale.dashboard_comprador_label_lastname}</label>
                <span className="red-text">{errorApellido}</span>
            </div>


            <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={props.form.nacimiento}
                    error={errorNacimiento}
                    id="nacimiento"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorNacimiento
                    })}
                />
                <label htmlFor="nacimiento">{props.locale.dashboard_comprador_label_yearofbirth}</label>
                <span className="red-text">{errorNacimiento}</span>
            </div>
            <div className="input-field col s12 m6 l6">
                <select value={props.form.sexo} onChange={update} id="sexo">
                    <option value="" disabled >
                        {props.locale.dashboard_comprador_label_sex}
                    </option>
                    <option value="F">
                        {props.locale.dashboard_comprador_label_female}
                    </option>
                    <option value="M">
                        {props.locale.dashboard_comprador_label_male}
                    </option>
                    <option value="O">
                        {props.locale.dashboard_comprador_label_gender_other}
                    </option>
                    <option value="N">
                        {props.locale.dashboard_comprador_label_gender_not_specified}
                    </option>
                </select>
                <label>{props.locale.dashboard_comprador_label_sex}</label>
                <span className="red-text">{errorSexo}</span>
            </div>
            <div className="input-field col s12 m6 l6">
                <select value={props.form.pais} onChange={update} id="pais" className="select">
                    <option value="" disabled>
                        {props.locale.dashboard_comprador_label_country}
                    </option>
                    {countries}
                </select>
                <label>{props.locale.dashboard_comprador_label_country_select}</label>
                <span className="red-text">{errorPais}</span>
            </div>
            {(props.form.pais !== "MX" && props.form.pais !== "US") ?
                <>
                    <div className="input-field col s12 m6 l6">
                        <input
                            onChange={update}
                            value={props.form.ciudad}
                            error={errorCiudad}
                            id="ciudad"
                            type="text"
                             className={classnames("font-face-msb", {
                                invalid: errorCiudad
                            })}
                        />
                        <label htmlFor="ciudad">{props.locale.dashboard_comprador_label_city}</label>
                        <span className="red-text">{errorCiudad}</span>
                    </div>
                </> :
                <></>
            }
            <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={props.form.codigoPostal}
                    error={errorCodigoPostal}
                    id="codigoPostal"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorCodigoPostal
                    })}
                />
                <label htmlFor="codigoPostal">{props.locale.dashboard_comprador_label_zipcode}</label>
                <span className="red-text">{errorCodigoPostal}</span>
            </div>
            <div className="input-field col s12 m6 l6">
                <input
                    onChange={update}
                    value={props.form.carrera}
                    error={errorCarrera}
                    id="carrera"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorCarrera
                    })}
                />
                <label htmlFor="carrer">{props.locale.dashboard_comprador_label_visit_occupation}</label>
                <span className="red-text">{errorCarrera}</span>
            </div>

            <div className="input-field col s12 m6 l6">
              <input
                onChange={update}
                value={props.form.nombreEmpresa}
                error={errorNombreEmpresa}
                id="nombreEmpresa"
                type="text"
                 className={classnames("font-face-msb", {
                  invalid: errorNombreEmpresa
                })}
              />
              <label htmlFor="nombreEmpresa">{props.locale.dashboard_comprador_label_nameofcompany_member}</label>
              <span className="red-text">{errorNombreEmpresa}</span>
            </div>

            <div className="input-field col s12 m6 l6">
              <input
                onChange={update}
                value={props.form.notas}
                error={errorNotas}
                id="notas"
                type="text"
                 className={classnames("font-face-msb", {
                  invalid: errorNotas
                })}
              />
              <label htmlFor="notas">{props.locale.dashboard_comprador_label_visit}</label>
              <span className="red-text">{errorNotas}</span>
            </div>            


            <Stats step={2} {...props} nextStep={submit} locale={props.locale} disableSubmit={props.disableSubmit} />
        </div>

    );
};
