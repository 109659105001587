import axios from "axios";

// Get Countries Details
export const getCountries = () => {
  try {
    return axios.get("/api/countries");
  } catch (error) {
    console.error(error)
  }
};

// Get states 
export const getStates = (country) => {
    try {
      return axios.get("/api/countries/states/"+country);
    } catch (error) {
      console.error(error)
    }
  };

  // Get states 
export const getCities = (country, state) => {
  try {
    return axios.get("/api/countries/cities/"+country+"/"+state);
  } catch (error) {
    console.error(error)
  }
};