import axios from "axios";


// Get states 
export const getZipCode = (country, zipcode) => {
    try {
      return axios.get("/api/countries/zipcodes/"+country+"/"+zipcode);
    } catch (error) {
      console.error(error)
    }
  };