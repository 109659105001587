import React, { useEffect, useState  } from "react";
import { withRouter, useParams } from "react-router-dom";
import { ReSendInvitationByAttendeeId } from "../../actions/attendeeActions"


const SendInvitation = () => {

    const { id } = useParams();  

    const [state, updateState] = useState({name:"", email:"", mensaje1:"", mensaje2:""});

    useEffect(() => {
      ReSendInvitationByAttendeeId(id)
      .then(res => {updateState({name:res.data.nombres, email:res.data.email, mensaje1:"Hola "+ res.data.nombres+" se reenvio tu invitacion satisfactoriamente 👏", mensaje2:"La invitacion fue enviada al correo electronico "})})
      .catch(err => 
          { updateState({mensaje1:"No fue posible reenviar la invitacion 😔", mensaje2:"Intente mas tarde por favor"})
          console.log(err);
          return null;});

  }, [id]);


    return (
      <div style={{ height: "90vh" }} className="container valign-wrapper">
        <div className="row">
          <div className="col s12 m12 l12 center-align">
            <h4>
              <p>{state.mensaje1}</p>
            </h4>
            <h5>
                <p>{state.mensaje2}<b>{state.email}</b></p>
            </h5>
        </div>
        </div>
      </div>
    );
  
}

export default (withRouter(SendInvitation));