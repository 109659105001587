import LocalizationEn from "./english"
import LocalizationEs from "./espaniol"

class Localization
{
    static getLocalizationInstance(language)
    {
        if (language!= null && language.includes("en")) {
            return new LocalizationEn();
          }
          else {
            return new LocalizationEs();
          }
    }

}
export default Localization;
