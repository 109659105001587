import React, { useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import { disableControlsByClass } from "./utils"
import isEmpty from "is-empty";

export const TypeBusiness = (props) => {

    const [errorGiro, setErrorGiro] = useState('');
    const errorGiroOtros = '';

    const update = (e) => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        if (target.type === 'checkbox') {
            if (validateSelection(e.target.className)) {
                props.update(id, value);
            }
        }
        else {
            props.update(id, value);
        }
    };

    const blur = (e) => {
        if (e.target.type === 'text') {
            if (validateSelection(e.target.className)) {
                props.update(e.target.id, e.target.value);
            }
        }
    };

    const validateSelection = (category) => {
        if (category === "Giro")
            return disableControlsByClass(category, 1);

        return true;
    };

    const validate = () => {

        let valid = true;
        let language = localStorage.getItem("exmi_language");

        // Errors clean up
        setErrorGiro("");

        //// Giro de la empresa
        props.form.giroMuebleria = !isEmpty(props.form.giroMuebleria) ? props.form.giroMuebleria : false;
        props.form.giroTiendaDepartamental = !isEmpty(props.form.giroTiendaDepartamental) ? props.form.giroTiendaDepartamental : false;
        props.form.giroConstruccion = !isEmpty(props.form.giroConstruccion) ? props.form.giroConstruccion : false;
        props.form.giroServicios = !isEmpty(props.form.giroServicios) ? props.form.giroServicios : false;
        props.form.giroExportador = !isEmpty(props.form.giroExportador) ? props.form.giroExportador : false;
        props.form.giroImportador = !isEmpty(props.form.giroImportador) ? props.form.giroImportador : false;
        props.form.giroOtros = !isEmpty(props.form.giroOtros) ? props.form.giroOtros : "";

        // Giro
        if (
            !props.form.giroMuebleria &&
            !props.form.giroTiendaDepartamental &&
            !props.form.giroConstruccion &&
            !props.form.giroServicios &&
            !props.form.giroExportador &&
            !props.form.giroImportador &&
            Validator.isEmpty(props.form.giroOtros)) {
            if (language === "es")
                setErrorGiro("Debe seleccionar al menos un Giro de la Empresa");
            else
                setErrorGiro("You must select at least one type of business");

            valid = false;
        }

        if (valid) {
            /*
            console.log(props.form.vip);

            if (!props.form.vip)
                props.submit();
            else
            */
            props.nextStep();
        }
    };

    const previous = () => {
        if (props.form.pais === "MX")
            props.goToStep(1);
        else
            props.goToStep(2);
    }

    return (
        <div>

            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <h4>
                    <b>{props.locale.dashboard_comprador_label_type_business}</b>
                </h4>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_furniture}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroMuebleria" onChange={update} checked={props.form.giroMuebleria} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_deparmentalstore}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroTiendaDepartamental" onChange={update} checked={props.form.giroTiendaDepartamental} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_construction_real_state}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroConstruccion" onChange={update} checked={props.form.giroConstruccion} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            <div className="input-field col s8">{props.locale.dashboard_comprador_label_decorationServices}</div>
            <div className="input-field col s4">
                <div className="switch">
                    <label>
                        <input type="checkbox" id="giroServicios" onChange={update} checked={props.form.giroServicios} className="Giro" />
                        <span className="lever"></span>
                    </label>
                </div>
            </div>

            {(props.form.pais === "MX") ?
                <>
                    <div className="input-field col s8">{props.locale.dashboard_comprador_label_exporter}</div>
                    <div className="input-field col s4">
                        <div className="switch">
                            <label>
                                <input type="checkbox" id="giroExportador" onChange={update} checked={props.form.giroExportador} className="Giro" />
                                <span className="lever"></span>
                            </label>
                        </div>
                    </div>
                </> :
                <>
                    <div className="input-field col s8">{props.locale.dashboard_comprador_label_importer}</div>
                    <div className="input-field col s4">
                        <div className="switch">
                            <label>
                                <input type="checkbox" id="giroImportador" onChange={update} checked={props.form.giroImportador} className="Giro" />
                                <span className="lever"></span>
                            </label>
                        </div>
                    </div>
                </>
            }

            <div className="input-field col s12">
                <input
                    onChange={update}
                    onBlur={blur}
                    value={props.form.giroOtros}
                    error={errorGiroOtros}
                    id="giroOtros"
                    type="text"
                     className={classnames("font-face-msb", {
                        invalid: errorGiroOtros
                    }, "Giro")}
                />
                <label htmlFor="giroOtros">{props.locale.dashboard_comprador_label_others}</label>
                <span className="red-text">{errorGiroOtros}</span>
            </div>

            <div className="input-field col s12"><span className="red-text">{errorGiro}</span></div>
            {("1" === "0") ?
                <>
                    <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                        <h4>
                            <b>{props.locale.dashboard_comprador_label_pre_market}</b>
                        </h4>
                    </div>

                    <div className="input-field col s8">{props.locale.dashboard_comprador_label_pre_market_desc}</div>
                    <div className="input-field col s4">
                        <div className="switch">
                            <label>
                                <input type="checkbox" id="vip" onChange={update} checked={props.form.vip} disabled={(props.form.vipProcessed === true || props.form.vipProcessed === "true")} />
                                <span className="lever"></span>
                            </label>
                        </div>
                    </div>
                </>
                : <></>
            }
            <br></br><br></br><br></br>
            <Stats step={2} {...props} nextStep={validate} previousStep={previous} locale={props.locale} vip={props.form.vip} />
        </div>
    )
};