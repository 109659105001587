import React, { useEffect, useState } from "react";
import { Link, withRouter, useHistory, useParams } from "react-router-dom";
import { getAttendeeByUserId } from "../../actions/attendeeActions";
import Localization from "../localization/localization"
import QRCode from "react-qr-code";



const QR = () => {
    
    const { id } = useParams();
    const [guid, setGuid] =  useState('');

    const locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
    const history = useHistory();

    useEffect(() => {
        getAttendeeByUserId(id)
          .then(response => {
            if (response.data !== null) {
                console.log(response.data.guid);
                setGuid(response.data.guid);
            }
        })
    },[]);

    return (

        <div className="col s12 m12 l7 container contentaligned">
            <Link to="/dashboard" className="btn-flat waves-effect">
                <i className="material-icons left">keyboard_backspace</i> {locale.dashboard_comprador_label_backtohome}
            </Link>        

            <div style={{ background: 'white', padding: '16px' }}>
            <div style={{ height: "auto", margin: "0 auto", width: "70%" }}>
                    <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={guid}
                    viewBox={`0 0 256 256`}
                    />
            </div>
        </div>
        </div>
    );
};


export default (withRouter(QR));
