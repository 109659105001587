import React, { useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import { disableControlsByClass } from "./utils"
import isEmpty from "is-empty";

export const Target = (props) => {


  const [errorMercadoAtiende, setErrorMercadoAtiende] = useState('');
  const errorMercadoAtiendeOtros = '';

  const update = (e) => {

    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;

    if (target.type === 'checkbox') {
      if (validateSelection(e.target.className)) {
        props.update(id, value);
      }
    }
    else {
      props.update(id, value);
    }
  };

  const blur = (e) => {
    if (e.target.type === 'text') {
      if (validateSelection(e.target.className)) {
        props.update(e.target.id, e.target.value);
      }
    }
  };

  const validateSelection = (category) => {
    return disableControlsByClass(category, 3);
  };

  const validate = () => {

    let valid = true;
    let language = localStorage.getItem("exmi_language");

    // Errors clean up
    setErrorMercadoAtiende("");

    //// Mercado al que atiende
    props.form.mercadoAtiendeCorporativo = !isEmpty(props.form.mercadoAtiendeCorporativo) ? props.form.mercadoAtiendeCorporativo : false;
    props.form.mercadoAtiendeHospitalidad = !isEmpty(props.form.mercadoAtiendeHospitalidad) ? props.form.mercadoAtiendeHospitalidad : false;
    props.form.mercadoAtiendeResidencial = !isEmpty(props.form.mercadoAtiendeResidencial) ? props.form.mercadoAtiendeResidencial : false;
    props.form.mercadoAtiendeConstruccion = !isEmpty(props.form.mercadoAtiendeConstruccion) ? props.form.mercadoAtiendeConstruccion : false;
    props.form.mercadoAtiendeOtros = !isEmpty(props.form.mercadoAtiendeOtros) ? props.form.mercadoAtiendeOtros : "";


    // Mercado al que atiende
    if (
      !props.form.mercadoAtiendeCorporativo &&
      !props.form.mercadoAtiendeHospitalidad &&
      !props.form.mercadoAtiendeResidencial &&
      !props.form.mercadoAtiendeConstruccion &&
      Validator.isEmpty(props.form.mercadoAtiendeOtros)) {
      if (language === "es")
        setErrorMercadoAtiende("Debe seleccionar al menos un Mercado al que atiende");
      else
        setErrorMercadoAtiende("You must select at least one target business");

      valid = false;
    }

    if (valid)
      props.submit();
  };

  return (
    <div>

      <div className="col s12" style={{ paddingLeft: "11.250px" }}>
        <h4>
          <b>{props.locale.dashboard_comprador_label_target}</b>
        </h4>
      </div>

      <div className="input-field col s8">{props.locale.dashboard_comprador_label_target_residential}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="mercadoAtiendeResidencial" onChange={update} checked={props.form.mercadoAtiendeResidencial} className="MercadoAtiende" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s8">{props.locale.dashboard_comprador_label_target_hotels}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="mercadoAtiendeHospitalidad" onChange={update} checked={props.form.mercadoAtiendeHospitalidad} className="MercadoAtiende" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s8">{props.locale.dashboard_comprador_label_target_corporate}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="mercadoAtiendeCorporativo" onChange={update} checked={props.form.mercadoAtiendeCorporativo} className="MercadoAtiende" />
            <span className="lever"></span>
          </label>
        </div>
      </div>


      <div className="input-field col s8">{props.locale.dashboard_comprador_label_target_construction}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="mercadoAtiendeConstruccion" onChange={update} checked={props.form.mercadoAtiendeConstruccion} className="MercadoAtiende" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s12">
        <input
          onChange={update}
          onBlur={blur}
          value={props.form.mercadoAtiendeOtros}
          error={errorMercadoAtiendeOtros}
          id="mercadoAtiendeOtros"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorMercadoAtiendeOtros
          }, "MercadoAtiende")}
        />
        <label htmlFor="mercadoAtiendeOtros">{props.locale.dashboard_comprador_label_target_otros}</label>
        <span className="red-text">{errorMercadoAtiendeOtros}</span>
      </div>

      <div className="input-field col s12"><span className="red-text">{errorMercadoAtiende}</span></div>

      <Stats step={4} {...props} nextStep={validate} disableSubmit={props.disableSubmit} />
    </div>
  )
};