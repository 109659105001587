import React, { useState } from "react";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats"
import { disableControlsByClass } from "./utils"
import isEmpty from "is-empty";

export const MainActivityCompany = (props) => {

  const [errorPrincipalActividad, setErrorPrincipalActividad] = useState('')
  const errorPrincipalActividadOtros = '';

  const update = (e) => {

    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const id = target.id;

    if (target.type === 'checkbox') {
      if (validateSelection(e.target.className)) {
        props.update(id, value);
      }
    }
    else {
      props.update(id, value);
    }
  };

  const blur = (e) => {
    if (e.target.type === 'text') {
      if (validateSelection(e.target.className)) {
        props.update(e.target.id, e.target.value);
      }
    }
  };

  const validateSelection = (category) => {
    return disableControlsByClass(category, 3);
  };

  const validate = () => {

    let valid = true;
    let language = localStorage.getItem("exmi_language");

    // Errors clean up
    setErrorPrincipalActividad("");

    //// Principal Actividad
    props.form.principalActividadVentaPublico = !isEmpty(props.form.principalActividadVentaPublico) ? props.form.principalActividadVentaPublico : false;
    props.form.principalActividadMayoreo = !isEmpty(props.form.principalActividadMayoreo) ? props.form.principalActividadMayoreo : false;
    props.form.principalActividadDistribuidor = !isEmpty(props.form.principalActividadDistribuidor) ? props.form.principalActividadDistribuidor : false;
    props.form.principalActividadBrokerIntermediario = !isEmpty(props.form.principalActividadBrokerIntermediario) ? props.form.principalActividadBrokerIntermediario : false;
    props.form.principalActividadConstruccionHospitalidad = !isEmpty(props.form.principalActividadConstruccionHospitalidad) ? props.form.principalActividadConstruccionHospitalidad : false;
    props.form.principalActividadFabricante = !isEmpty(props.form.principalActividadFabricante) ? props.form.principalActividadFabricante : false;
    props.form.principalActividadOtros = !isEmpty(props.form.principalActividadOtros) ? props.form.principalActividadOtros : "";

    // Principal Actividad
    if (
      !props.form.principalActividadVentaPublico &&
      !props.form.principalActividadMayoreo &&
      !props.form.principalActividadDistribuidor &&
      !props.form.principalActividadBrokerIntermediario &&
      !props.form.principalActividadConstruccionHospitalidad &&
      !props.form.principalActividadFabricante &&
      Validator.isEmpty(props.form.principalActividadOtros)) {
      if (language === "es")
        setErrorPrincipalActividad("Debe seleccionar al menos una Actividad Principal");
      else
        setErrorPrincipalActividad("You must select at least one main business activity");

      valid = false;
    }

    if (valid)
      props.nextStep();

  };

  return (
    <div>

      <div className="col s12" style={{ paddingLeft: "11.250px" }}>
        <h4>
          <b>{props.locale.dashboard_comprador_label_business}</b>
        </h4>
      </div>
      <div className="input-field col s8">{props.locale.dashboard_comprador_label_retailer}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="principalActividadVentaPublico" onChange={update} checked={props.form.principalActividadVentaPublico} className="PrincipalActividad" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s8">{props.locale.dashboard_comprador_label_wholesale}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="principalActividadMayoreo" onChange={update} checked={props.form.principalActividadMayoreo} className="PrincipalActividad" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s8">{props.locale.dashboard_comprador_label_distributor}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="principalActividadDistribuidor" onChange={update} checked={props.form.principalActividadDistribuidor} className="PrincipalActividad" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s8">{props.locale.dashboard_comprador_label_broker}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="principalActividadBrokerIntermediario" onChange={update} checked={props.form.principalActividadBrokerIntermediario} className="PrincipalActividad" />
            <span className="lever"></span>
          </label>
        </div>
      </div>


      <div className="input-field col s8">{props.locale.dashboard_comprador_label_construction_hospitality}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="principalActividadConstruccionHospitalidad" onChange={update} checked={props.form.principalActividadConstruccionHospitalidad} className="PrincipalActividad" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s8">{props.locale.dashboard_comprador_label_manufacturer}</div>
      <div className="input-field col s4">
        <div className="switch">
          <label>
            <input type="checkbox" id="principalActividadFabricante" onChange={update} checked={props.form.principalActividadFabricante} className="PrincipalActividad" />
            <span className="lever"></span>
          </label>
        </div>
      </div>

      <div className="input-field col s12">
        <input
          onChange={update}
          onBlur={blur}
          value={props.form.principalActividadOtros}
          error={errorPrincipalActividadOtros}
          id="principalActividadOtros"
          type="text"
           className={classnames("font-face-msb", {
            invalid: errorPrincipalActividadOtros
          }, "PrincipalActividad")}
        />
        <label htmlFor="principalActividadOtros">{props.locale.dashboard_comprador_label_others}</label>
        <span className="red-text">{errorPrincipalActividadOtros}</span>
      </div>

      <div className="input-field col s12"><span className="red-text">{errorPrincipalActividad}</span></div>

      <Stats step={3} {...props} nextStep={validate}  vip={props.form.vip} />
    </div>
  )
};