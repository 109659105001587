import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, loginSocialUser } from "../../actions/authActions";
import classnames from "classnames";
import Localization from "../localization/localization";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  MicrosoftLoginButton
} from "react-social-login-buttons";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  componentDidMount() {
    try {
      this.props.loginSocialUser();
    }
    catch (err) {
      console.log(err);
    }
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value.trim() });
  };

  onSubmit = e => {
    e.preventDefault();

    let language = localStorage.getItem("exmi_language");
    if (language == null)
      language = "es";


    const userData = {
      email: this.state.email,
      password: this.state.password,
      locale: language
    };

    this.props.loginUser(userData);
  };

  getBaseUrl = () => {
    return (window.location.origin === "http://localhost:3000") ? "http://localhost:5000" : window.location.origin;
  }

  google = () => {
    window.open(this.getBaseUrl() + "/api/users/google", "_self");
  };

  facebook = () => {
    window.open(this.getBaseUrl() + "/api/users/facebook", "_self");
  };

  outlook = () => {
    window.open(this.getBaseUrl() + "/api/users/outlook", "_self");
  };



  render() {
    const { errors } = this.state;

    return (
      <div>
        <div>
          <div className="col s12 m12 l6 container contentaligned">
            <Link to="/" className="btn-flat waves-effect">
              <i className="material-icons left">keyboard_backspace</i> {this.locale.layout_login_label_backtohome}
            </Link>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.layout_login_label_login}</b>
              </h4>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div class="row">
                <div class="col s12 m6 l6">
                  <p className="grey-text text-darken-1">
                    <Link to="/passwordReset" className="linkColor">{this.locale.layout_login_link_forgotpassword}</Link>
                    {this.locale.layout_login_label_noaccount} <Link to="/register" className="linkColor">{this.locale.layout_login_link_createaccount}</Link>
                  </p>
                  <div className="input-field col s12">
                    <input
                      autoFocus
                      onChange={this.onChange}
                      value={this.state.email}
                      error={errors.email}
                      id="email"
                      type="email"
                      className={classnames("font-face-msb", {
                        invalid: errors.email || errors.emailnotfound || errors.usernotactive
                      })}
                    />
                    <label htmlFor="email">{this.locale.layout_login_label_email}</label>
                    <span className="red-text">
                      {errors.email}
                      {errors.emailnotfound}
                      {errors.usernotactive}
                    </span>
                  </div>
                  <div className="input-field col s12">
                    <input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      type="password"
                      className={classnames("font-face-msb", {
                        invalid: errors.password || errors.passwordincorrect
                      })}
                    />
                    <label htmlFor="password">{this.locale.layout_login_label_password}</label>
                    <span className="red-text">
                      {errors.password}
                      {errors.passwordincorrect}
                    </span>
                  </div>
                  <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                    <button
                      style={{
                        width: "200px",
                        borderRadius: "3px",
                        letterSpacing: "1.5px",
                        marginTop: "1rem"
                      }}
                      type="submit"
                      className="btn-large hoverable buttonBackgroundColor font-face-msb"
                    >
                      {this.locale.layout_login_button_login}
                    </button>
                  </div>

                </div>
                <div class="center col s12 m2 l2">
                  <h4>Or</h4>
                </div>
                <div class="col s12 m4 l4">
                  <div class="hide-md-lg">
                    <p>{this.locale.social_media_message}</p>
                  </div>
                  <FacebookLoginButton onClick={this.facebook} text={this.locale.social_login_facebook} />
                  <br />
                  <GoogleLoginButton onClick={this.google} text={this.locale.social_login_gmail} />
                  <br />
                  <MicrosoftLoginButton onClick={this.outlook} text={this.locale.social_login_hotmail} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  loginSocialUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, loginSocialUser }
)(Login);
