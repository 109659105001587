import React from "react";


export const Stats = ({
  currentStep,
  firstStep,
  goToStep,
  lastStep,
  nextStep,
  previousStep,
  totalSteps,
  step,
  locale,
  disableSubmit
}) => (
  <div className="col s12 m12 l12">
    {step > 1 &&

      <button
        style={{
          width: "160px",
          borderRadius: "3px",
          letterSpacing: "1.5px",
          marginTop: "1rem",
          marginRight: "50px"
        }}

        className="btn-large waves-effect waves-light hoverable buttonSoporteBackgroundColor font-face-msb"
        onClick={previousStep}
      >
        {locale.button_wizard_back}
      </button>
    }
    {(step < totalSteps && step < 4) ?
      <button
        style={{
          width: "200px",
          borderRadius: "3px",
          letterSpacing: "1.5px",
          marginTop: "1rem"
        }}

        className="btn-large hoverable buttonBackgroundColor font-face-msb"
        onClick={nextStep}
      >
        {locale.button_wizard_continue}
      </button>
      :
          <button
            style={{
              width: "200px",
              borderRadius: "3px",
              letterSpacing: "1.5px",
              marginTop: "1rem"
            }}
            disabled={disableSubmit}
            className="btn-large hoverable buttonBackgroundColor font-face-msb"
            onClick={nextStep}
          >
            {locale.button_wizard_finish}
          </button>        
    }
  </div>
);