import axios from "axios";
import { GET_ERRORS } from "./types";
//import ReactPixel from 'react-facebook-pixel';

// Register Attendee

export const registerAttendee = (userData, history) => dispatch => {
  console.log(userData);
  axios
    .post("/api/attendees/add", userData)
    //.then(res=> ReactPixel.track('Lead', {content_name:'Registro'}))
    .then(res => history.push("/compradorlanding?action=add&id=" + userData.userId))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Register Attendee
export const registerAttendeeNew = (userData) => {
  //console.log(userData);
  
  return axios.post("/api/attendees/add", userData);

};


// Actualiza Attendee
export const updateAttendee = (userId, userData, history) => dispatch => {
  axios
    .post("/api/attendees/update/" + userId, userData)
    .then(res => history.push("/compradorlanding?action=update&id=" + userId))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const updateAttendeeNew = (userId, userData) => {
  return axios
    .post("/api/attendees/update/" + userId, userData)
};


export const preMarketConfirmation = (attendeeId, vip) => {
  return axios
    .post("/api/attendees/premarketconfirmation/" + attendeeId+"/"+vip);
};

// Get Atendee Details
export const getAttendeeByUserId = (userId) => {
  try {
    return axios.get("/api/attendees/byUserId/" + userId);
  } catch (error) {
    console.error(error)
  }
};

// Get Atendee Details By email
export const getAttendeeByEmail = (email) => {
  try {
    return axios.get("/api/attendees/byEmail/" + email);
  } catch (error) {
    console.error(error)
  }
};

// Get Atendee Details By email
export const getAttendeeByEmailAndUpdate = (email, userId) => {
  try {
    return axios.get("/api/attendees/byEmailAndUpdate/" + email +"/"+userId);
  } catch (error) {
    console.error(error)
  }
};

// Get PreAtendee Details
export const getPreAttendeeByUserId = (userId) => {
  try {
    return axios.get("/api/attendees/PreByUserId/" + userId);
  } catch (error) {
    console.error(error)
  }
};

// Get Atendee Details
export const getAttendeeByAttendeeId = (attendeeId) => {
  try {
    return axios.get("/api/attendees/" + attendeeId);
  } catch (error) {
    console.error(error)
  }
};



// Send Invitation by User Id
export const ReSendInvitation = (userId) => {
  try {
    return axios.get("/api/attendees/resendinvitation/" + userId);
  }
  catch (error) {
    console.error(error)
  }
};

// Send Invitation by User Id
export const ReSendInvitationByAttendeeId = (attendeeId) => {
  try {
    return axios.get("/api/attendees/resendinvitationAttendeeId/" + attendeeId);
  }
  catch (error) {
    console.error(error)
  }
};



// Register Student/invitated
export const registerGeneric = (userData, history) => dispatch => {
  axios
    .post("/api/attendees/add", userData)
    //.then(res=> console.log(res.data))
    .then(res => history.push("/landing?id=" + res.data.toString()))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};
