import React, { Component } from "react";
import queryString from 'query-string'
import { Link } from "react-router-dom";
import { getAttendeeByAttendeeId } from "../../actions/attendeeActions"
import Localization from "../localization/localization"

class SocialLoginError extends Component {

  constructor() {
    super();
    /*
    this.state = {
      name: "",
      email: "",
    };
*/
    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));

  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search)

    /*
    getAttendeeByAttendeeId(params.id)
      .then(res => this.setState({ name: res.data.nombres, email: res.data.email }))
      .catch(err => {
        console.log(err);
        return null;
      });
    */
  }

  render() {
    return (
      <div class="col s12 m12 l8 container">
        <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
          <div>
            <h4 class="font-face-mb">
              <p>{this.locale.error_message_social_login}</p>
            </h4>
            <br/>
            <br/>
            <br/>
            <Link
                to="/login"
                style={{
                  width: "437px",
                  borderRadius: "3px",
                  letterSpacing: "",
                  marginTop: "1rem"
                }}
                className="btn-large hoverable buttonBackgroundColor"
              >
                {this.locale.error_button_login}
              </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default SocialLoginError;