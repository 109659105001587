import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";

// Register User
export const registerUser = (userData, history) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => history.push("/activationemailsent?id=" + res.data._id))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      //console.log(decoded);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login - get user token
export const loginSocialUser = () => dispatch => {
  axios
    .get("/api/users/login/success", { withCredentials: true })
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;

      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      //console.log(decoded);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// Log user out
export const logoutUser = () => dispatch => {
  axios
    .post("/api/users/logout", { withCredentials: true })
    .then(() => {
      // Remove token from local storage
      localStorage.removeItem("jwtToken");
      // Remove auth header for future requests
      setAuthToken(false);
      // Set current user to empty object {} which will set isAuthenticated to false
      dispatch(setCurrentUser({}));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Account Activation (No in use)
export const accountActivation = (activationId) => dispatch => {
  axios
    .post("/api/users/activation", activationId)
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Password reset Email Sent
export const passwordUserResetEmail = (userData, history) => dispatch => {
  axios
    .post("/api/users/passwordreset", userData)
    .then(res => history.push("/login"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};


// Password reset
export const passwordUserReset = (userData, history) => dispatch => {
  try {
    axios
      .post("/api/users/passwordresetconfirmation", userData)
      .then(res => history.push("/login"))
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      );
  }
  catch (err) { console.log(err); }
};


export const userDetailsAsync = async (usuarioId) => {
  try {
    return await axios.get("/api/users/usuario/" + usuarioId)
  } catch (error) {
    console.error(error)
  }
};

export const userDetails = async (usuarioId) => {
  const user = await userDetailsAsync(usuarioId);
  return user;
};

export const resendActivationEmail = async (usuarioId, language) => {
  try {
    return await axios.get("/api/users/activationemailresend/" + usuarioId + "/" + language);
  } catch (error) {
    console.error(error)
  }
};

export const userActivation = async (usuarioId) => {
  try {
    const activationMessage = {
      activation: usuarioId
    };

    return await axios.post("/api/users/activation", activationMessage)

  } catch (error) {
    console.error(error)
  }
};
