export const getControlsWithValueByClass = (className) => {
    let counter = 0;
    let elements = document.getElementsByClassName(className);
    let l = elements.length;
    while (l--) {

      if (elements[l].type === "text" && elements[l].value.length > 0) {
        counter++;
      }
      else {
        if (elements[l].type === "checkbox" && elements[l].checked) {
          counter++;
        }
      }
    }
    return counter;
  }

export const disableControlsByClass = (className, maxControlsEnabled) => {
    let elements = document.getElementsByClassName(className);
    let l = elements.length;
    let counter = 0;
    counter = getControlsWithValueByClass(className);

    if (counter >= maxControlsEnabled) {

      while (l--) {
        if (elements[l].type === "checkbox" && !elements[l].checked) {
          elements[l].disabled = true;
        }
        else {
          if (elements[l].type === "text" && elements[l].value === "")
            elements[l].disabled = true;
        }
      }
      if (counter === maxControlsEnabled)
        return true;
      else
        return false;
    }
    else {
      while (l--) {
        elements[l].disabled = false;
      }
      return true;
    }
  }