import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import Validator from "validator";
import { Stats } from "./Stats";
import { getAttendeeByEmail } from "../../actions/attendeeActions";


export const Email = (props) => {


    const history = useHistory();

    const [errorEmail, setErrorEmail] = useState('');

    const update = (e) => {
        props.update(e.target.id, e.target.value.trim());
    };

    const validate = () => {

        let valid = true;
        let language = localStorage.getItem("exmi_language");

        if (language === "es") {
            // Email checks
            if (Validator.isEmpty(props.form.email)) {
                setErrorEmail("El correo electrónico es requerido");
                valid = false;
            } else if (!Validator.isEmail(props.form.email)) {
                setErrorEmail("El correo electrónico capturado no es válido");
                valid = false;
            }
        }
        else {
            // Email checks
            if (Validator.isEmpty(props.form.email)) {
                setErrorEmail("Email is required");
                valid = false;
            } else if (!Validator.isEmail(props.form.email)) {
                setErrorEmail("Email is invalid");
                valid = false;
            }
        }

        if (valid) {
            // check if email is already in Database
            getAttendeeByEmail(props.form.email)
                .then(res => history.push("/invitadolanding?id=" + res.data._id))
                .catch(error => {
                    props.nextStep();
                });
        }

    };
    
    const inputElement = useRef(null);

    useEffect(() => {
      if (inputElement.current) {
        inputElement.current.focus();
      }
    }, [inputElement.current]);

    return (
        <div>
            <div className="input-field col s11">
                <input
                    ref={inputElement} 
                    placeholder=""
                    onChange={update}
                    value={props.form.email}
                    error={errorEmail}
                    id="email"
                    type="text"
                    className={classnames("font-face-msb", {invalid: errorEmail})}
                />
                <label htmlFor="email" className="active">{props.locale.dashboard_comprador_label_mail}</label>
                <span className="red-text">{errorEmail}</span>
            </div>

            <Stats step={1} {...props} nextStep={validate} locale={props.locale} />
        </div>

    );
};