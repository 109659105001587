import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { passwordUserResetEmail } from "../../actions/authActions";
import classnames from "classnames";
import Localization from "../localization/localization"


class PasswordResetEmail extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {}
    };
    
    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language")); 
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value.trim() });
  };

  onSubmit = e => {
    e.preventDefault();

    let language = localStorage.getItem("exmi_language");
    if (language == null)
      language = "es";


    const userEmail = {
      email: this.state.email,
      locale: language
    };

    this.props.passwordUserResetEmail(userEmail, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div>
          <div className="col s12 m12 l6 container contentaligned">
            <Link to="/" className="btn-flat waves-effect">
              <i className="material-icons left">keyboard_backspace</i>
              {this.locale.auth_passwordresetemail_label_backtohome}
            </Link>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.auth_passwordresetemail_label_formtitle}</b>
              </h4>
              <p className="grey-text text-darken-1">
              {this.locale.auth_passwordresetemail_label_message}
              </p>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <input
                  autoFocus
                  onChange={this.onChange}
                  value={this.state.email}
                  error={errors.email}
                  id="email"
                  type="email"
                   className={classnames("font-face-msb", {
                    invalid: errors.email || errors.emailnotfound
                  })}
                />
                <label htmlFor="email">{this.locale.auth_passwordresetemail_label_email}</label>
                <span className="red-text">
                  {errors.email}
                  {errors.emailnotfound}
                </span>
              </div>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "250px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn-large hoverable buttonBackgroundColor font-face-msb"
                >
                {this.locale.auth_passwordreset_button_confirmation}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}


PasswordResetEmail.propTypes = {
  passwordUserResetEmail : PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};



const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { passwordUserResetEmail }
)(withRouter(PasswordResetEmail));
