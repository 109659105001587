import React, { useState, useEffect } from "react";
import { getCountries, getStates } from "../../actions/countriesActions";

export const CompradorMinimalInformation = (props) => {


    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);

    const populateStates = (country) => {
        let states = [];
        getStates(country)
            .then(response => {
                states = response.data[0].states.map((state) => {
                    return <option key={state} value={state}>{state}</option>
                });

                setStates(states);
            })
            .catch(err => console.log(err));
    };

    useEffect(() => {
        getCountries()
            .then(response => {
                let countries = [];
                countries = response.data.map((country) => {
                    return <option key={country.iso2} value={country.iso2}>{country.name}</option>
                });

                setCountries(countries);
            });

        populateStates("MX");
    }, []);

    const update = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;
        props.update(id, value);
    };

    const onSubmit = e => {
        props.submit();
    }

    return (
        <div>
            <div className="col s12 m12 l12">
                <div className="col s12 m6 l6">
                    <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                        <h4>
                            <b>{props.locale.dashboard_comprador_label_personalinformation_minimal}</b>
                        </h4>
                    </div>

                    <div className="input-field col s12">
                        <input
                            readOnly
                            placeholder=""
                            value={props.form.email}
                            id="email"
                            type="email"
                        />
                        <label htmlFor="email" className="active">{props.locale.dashboard_comprador_label_mail}</label>
                    </div>
                    <div className="input-field col s12">
                        <input
                            readOnly
                            value={props.form.nombres}
                            id="nombres"
                            type="text"
                        />
                        <label htmlFor="nombres">{props.locale.dashboard_comprador_label_name}</label>
                    </div>
                    <div className="input-field col s12">
                        <input
                            readOnly
                            value={props.form.apellidos}
                            id="apellidos"
                            type="text"
                        />
                        <label htmlFor="apellidos">{props.locale.dashboard_comprador_label_lastname}</label>
                    </div>

                    <div className="input-field col s12">
                        <input
                            readOnly
                            value={props.form.nacimiento}
                            id="nacimiento"
                            type="text"
                        />
                        <label htmlFor="nacimiento">{props.locale.dashboard_comprador_label_yearofbirth}</label>
                    </div>
                    <div className="input-field col s12">
                        <input
                            readOnly
                            value={props.form.ciudad}
                            id="ciudad"
                            type="text"
                        />
                        <label htmlFor="ciudad">{props.locale.dashboard_comprador_label_city}</label>
                    </div>
                    <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                        <h4>
                            <b>{props.locale.dashboard_comprador_label_pre_market_minimal}</b>
                        </h4>
                    </div>
                    <div className="input-field col s8">{props.locale.dashboard_comprador_label_pre_market_desc_minimal}</div>
                    <div className="input-field col s4">
                        <div className="switch">
                            <label>
                                <input type="checkbox" autoFocus id="vip" onChange={update} checked={props.form.vip} />
                                <span className="lever"></span>
                            </label>
                        </div>
                    </div>
                    <div className="input-field col s8">{props.locale.dashboard_comprador_label_pre_market_vipprocessed}</div>
                    <div className="input-field col s4">
                        <div className="switch">
                            <label>
                                <input type="checkbox" id="vipProcessed" onChange={update} checked={props.form.vipProcessed} disabled />
                                <span className="lever"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col s12 m6 l6">
                    <div>
                        <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                            <h4>
                                <b>{props.locale.dashboard_comprador_label_companyinformation_minimal}</b>
                            </h4>
                        </div>
                        <div className="input-field col s12">
                            <input
                                readOnly
                                value={props.form.nombreEmpresa}
                                id="nombreEmpresa"
                                type="text"
                            />
                            <label htmlFor="nombreEmpresa">{props.locale.dashboard_comprador_label_nameofcompany}</label>
                        </div>
                        <div className="input-field col s12">
                            <input
                                readOnly
                                value={props.form.razonSocial}
                                id="razonSocial"
                                type="text"
                            />
                            <label htmlFor="razonSocial">{props.locale.dashboard_comprador_label_businessname}</label>
                        </div>
                        <div className="input-field col s12">
                            <input
                                readOnly
                                value={props.form.cargo}
                                id="cargo"
                                type="text"
                            />
                            <label htmlFor="cargo">{props.locale.dashboard_comprador_label_companyposition}</label>
                        </div>

                        <div className="input-field col s12">
                            <select value={props.form.pais} id="pais" disabled>
                                <option value="" readOnly>
                                    {props.locale.dashboard_comprador_label_country}
                                </option>
                                {countries}
                            </select>
                            <label>{props.locale.dashboard_comprador_label_country_select}</label>
                        </div>

                        <div className="input-field col s12">
                            <select value={props.form.estado} id="estado" disabled>
                                <option value="" >
                                    {props.locale.dashboard_comprador_label_state_select}
                                </option>
                                {states}
                            </select>
                            <label htmlFor="estado">{props.locale.dashboard_comprador_label_state}</label>
                        </div>

                        <div className="input-field col s12">
                            <input
                                readOnly
                                value={props.form.ciudadEmpresa}
                                id="ciudadEmpresa"
                                type="text"
                            />
                            <label htmlFor="ciudadEmpresa">{props.locale.dashboard_comprador_label_city}</label>
                        </div>
                        <div className="input-field col s12">
                            <input
                                readOnly
                                value={props.form.wwwEmpresa}
                                id="wwwEmpresa"
                                type="text"
                            />
                            <label htmlFor="wwwEmpresa">{props.locale.dashboard_comprador_label_companywebpage}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col s12 m6 l6">
                <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                    <button
                        style={{
                            width: "150px",
                            borderRadius: "3px",
                            letterSpacing: "1.5px",
                            marginTop: "1rem"
                        }}
                        onClick={onSubmit}
                        type="submit"
                        className="btn-large hoverable buttonBackgroundColor"
                    >
                        {props.locale.dashboard_comprador_label_pre_market_confirm_button}
                    </button>
                </div>
                <div >
                    <label>{props.locale.premarket_message}</label>
                </div>
            </div>

        </div>

    );
};