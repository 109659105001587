
import React, { Component } from "react";
import queryString from 'query-string'
import { Link } from "react-router-dom";
import { userActivation } from "../../actions/authActions"
import Localization from "../localization/localization";

class AccountActivation extends Component {

  constructor() {
    super();
    this.state = {
      userName: "",
      error: ""
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);

    userActivation(values.id)
      .then(res => this.setState({ userName: res.data }))
      .catch(err => this.setState({ error: err }));
  }

  render() {
    return (
      <div class="col s12 m12 l8 container">
        <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
          <div>
            <div className="font-face-mb titulo">
            <h4>
            {this.state.error.length === 0 ?
              <p>{this.locale.auth_activation_label_hello} {this.state.userName} {this.locale.auth_activation_label_message_success} 👏</p> :
              <p>{this.locale.auth_activation_label_hello} {this.state.userName} {this.locale.auth_activation_label_message_failure}</p>
            }
          </h4>
            </div>
            <br />
          <div className="col s12 m12 l12">
          <Link
            to="/login"
            style={{
              width: "350px",
              borderRadius: "3px",
              letterSpacing: "1.5px"
            }}
            className="btn-large hoverable buttonBackgroundColor"
          >
            {this.locale.auth_activation_label_button_login}
          </Link>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default AccountActivation;
