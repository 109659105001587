import React, { useEffect, useState } from "react";
import { withRouter, useParams } from "react-router-dom";

//import { useSelector } from "react-redux";
import { preMarketConfirmation, getAttendeeByAttendeeId } from "../../actions/attendeeActions";
import materialize from "materialize-css"
import Localization from "../localization/localization"

import { CompradorMinimalInformation } from "./CompradorMinimalInformation"


const PreMarketConfirmation = () => {

    //const auth = useSelector(state => state.auth);

    const { id } = useParams();

    const [state, updateState] = useState({
        form: {
            nacimiento: "",
            sexo: "",
            nombres: "",
            apellidos: "",
            email: "",
            celular: "",
            ciudad: "",
            codigoPostal: "",

            //// Empresa
            nombreEmpresa: "",
            razonSocial: "",
            cargo: "",
            telefono: "",
            codigoPostalEmpresa: "",
            pais: "MX",
            estado: "",
            ciudadEmpresa: "",
            colonia: "",
            domicilio: "",
            domicilioNumero: "",
            wwwEmpresa: "",
            redesSocialesUrlEmpresa: "",

            //// Principal Actividad Empresa
            principalActividadVentaPublico: false,
            principalActividadMayoreo: false,
            principalActividadDistribuidor: false,
            principalActividadBrokerIntermediario: false,
            principalActividadConstruccionHospitalidad: false,
            principalActividadFabricante: false,
            principalActividadOtros: "",

            //// Giro de la empresa
            giroMuebleria: false,
            giroTiendaDepartamental: false,
            giroConstruccion: false,
            giroServicios: false,
            giroExportador: false,
            giroOtros: "",
            giroImportador: false,


            //// Mercado al que atiende
            mercadoAtiendeCorporativo: false,
            mercadoAtiendeHospitalidad: false,
            mercadoAtiendeResidencial: false,
            mercadoAtiendeConstruccion: false,
            mercadoAtiendeOtros: "",

            ///// Interes
            interesesAccesorios: false,
            interesesArteSacro: false,
            interesesCentrosEntretenimiento: false,
            interesesCocinas: false,
            interesesColchones: false,
            interesesComedores: false,
            interesesIluminacion: false,
            interesesMecedoras: false,
            interesesMesasCentroAuxiliares: false,
            interesesInfantilesJuveniles: false,
            interesesMuebleOficina: false,
            interesesOtros: "",
            interesesRecamarasLiteras: false,
            interesesLiterasMadera: false,
            interesesServicios: false,
            interesesTerrazaJardin: false,
            interesesTapizados: false,
            interesesTextiles: false,
            interesesTapetes: false,


            publicityYes: "",
            publicityNo: "",
            /////
            //principalActividad: "",
            //productosInteres: "",
            categoria: "COMPRADOR",
            tipoRegistro: "pre",
            counter: 0,

            locale: "es",

            vip: false,
            vipProcessed: false
            //errors: {}
        }
        // demo: true, // uncomment to see more
    });

    const locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));

    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const submitForm = () => {
        //e.preventDefault();
        preMarketConfirmation(id, state.form.vip)
            .then(result=> materialize.toast({ html: locale.button_submission_premaket_success}))
            .catch(error => {
                materialize.toast({ html: locale.button_submission_error })
            });
    }

    useEffect(() => {
        getAttendeeByAttendeeId(id)
            .then(response => {
                if (response.data !== null) {
                    const { form } = state;

                    console.log(response.data);

                    form.nacimiento = response.data.nacimiento;
                    form.sexo = response.data.sexo;
                    form.nombres = response.data.nombres;
                    form.apellidos = response.data.apellidos;
                    form.email = response.data.email;
                    form.celular = response.data.celular;
                    form.telefono = response.data.telefono;
                    form.domicilio = response.data.domicilio;
                    form.colonia = response.data.colonia;
                    form.ciudad = response.data.ciudad;
                    form.codigoPostal = response.data.codigoPostal;
                    form.estado = response.data.estado;
                    form.pais = response.data.pais;
                    form.nombreEmpresa = response.data.nombreEmpresa;
                    form.razonSocial = response.data.razonSocial;
                    form.cargo = response.data.cargo;
                    form.emailEmpresa = response.data.emailEmpresa;
                    form.wwwEmpresa = response.data.wwwEmpresa
                    form.locale = response.data.locale;


                    form.nacimiento = response.data.nacimiento;
                    form.sexo = response.data.sexo;
                    form.nombres = response.data.nombres;
                    form.apellidos = response.data.apellidos;
                    form.email = response.data.email;
                    form.celular = response.data.celular;
                    form.telefono = response.data.telefono;
                    form.domicilio = response.data.domicilio;
                    form.colonia = response.data.colonia;
                    form.ciudad = response.data.ciudad;
                    form.codigoPostal = response.data.codigoPostal;
                    form.estado = response.data.estado;
                    form.pais = response.data.pais;
                    form.nombreEmpresa = response.data.nombreEmpresa;
                    form.razonSocial = response.data.razonSocial;
                    form.cargo = response.data.cargo;
                    form.wwwEmpresa = response.data.wwwEmpresa;
                    form.codigoPostalEmpresa = response.data.codigoPostalEmpresa;
                    form.ciudadEmpresa = response.data.ciudadEmpresa;
                    form.domicilioNumero = response.data.domicilioNumero;
                    form.redesSocialesUrlEmpresa = response.data.redesSocialesUrlEmpresa;
 
                    form.locale = response.data.locale;

                    form.vip = ((response.data.vip === "true") ? true : false);
                    form.vipProcessed = ((response.data.vipProcessed === "true") ? true : false);

                    updateState({
                        ...state,
                        form,
                    });
                }

                materialize.AutoInit();
                materialize.updateTextFields();
            })
            .catch(error => {console.log(error);
                materialize.toast({ html: locale.premarket_error_buyer_not_found  })});


        materialize.AutoInit();
        materialize.updateTextFields();
    }, []);

    return (
        <div className="container ">
            <div className="login row">
                <div>
                    <CompradorMinimalInformation update={updateForm} locale={locale} form={state.form} submit={submitForm} />
                </div>
            </div>
        </div>
    );
};


export default (withRouter(PreMarketConfirmation));
