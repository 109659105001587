import React from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import Localization from "../localization/localization"

const FAQ = () => {

    const locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
    const history = useHistory();

    const onLinkClick = (e) => {
        e.preventDefault();
        history.goBack();
    };


    return (
        <div class="col s12 m12 l8 container overflowDiv">
            <div class="col s12 l12">
                <Link to="/" className="btn-flat waves-effect" onClick={ onLinkClick}>
                    <i className="material-icons left">keyboard_backspace</i> {locale.layout_login_label_backtohome}
                </Link>
            </div>
            <div class="col s12 l12 center contentaligned valign-wrapper">
                <div>
                    <h5 className="font-face-mb titulo">
                        {locale.soporte_FAQ}
                    </h5>
                    <div class="textoJustificado">
                        <div class="col s12 m12 l12">
                            <h6 className="font-face-mb">
                                {locale.FAQ_question_2}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_2}
                            </h6>
                            <br />
                            <h6 className="font-face-mb">
                                {locale.FAQ_question_3}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_3}
                            </h6>
                            <br />
                            <h6 className="font-face-mb">
                                {locale.FAQ_question_4}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_4}
                            </h6>
                            <br />
                            <h6 className="font-face-mb">
                                {locale.FAQ_question_5}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_5}
                            </h6>
                            <br />
                            <h6 className="font-face-mb">
                                {locale.FAQ_question_6}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_6}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_6_1}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_6_2}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_6_3}
                            </h6>
                            <br />
                            <h6 className="font-face-mb">
                                {locale.FAQ_question_7}
                            </h6>
                            <h6>
                                {locale.FAQ_answer_7}
                            </h6>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}


export default (withRouter(FAQ));