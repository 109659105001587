import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
//import { getAttendeeByUserId, ReSendInvitation, getAttendeeByEmail, getAttendeeByEmailAndUpdate } from "../../actions/attendeeActions"
import { ReSendInvitation, getAttendeeByEmailAndUpdate } from "../../actions/attendeeActions"
import { withRouter } from "react-router-dom";
import materialize from "materialize-css";
import Localization from "../localization/localization";


class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userRegistered: false,
      vip: false,
      vipProcessed: false,
      categoria: "COMPRADOR",
      categoriaDesc: "",
    };

    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  getCategoryDesc = categoria => {
    if (categoria === "VISITANTE") {
      return ((localStorage.getItem("exmi_language") === "es") ? "INVITADO" : "GUEST");
    }
    else if (categoria === "ESTUDIANTE") {
      return ((localStorage.getItem("exmi_language") === "es") ? "ESTUDIANTE" : "STUDENT");
    }
    return categoria;
  }


  componentDidMount() {
    const { user } = this.props.auth;

    getAttendeeByEmailAndUpdate(user.email, user.id)
      .then(response =>
        this.setState({
          userRegistered: (response.data.email.toString() === user.email.toString()),
          categoria: response.data.categoria
        }))
      .catch(error => console.log(error));


  }


  onUpdateAttendee = e => {
    const { user } = this.props.auth;
    let path = "/comprador/" + user.id;
    this.props.history.push(path);
  }

  onGetQR = e => {
    const { user } = this.props.auth;
    let path = "/qr/" + user.id;
    this.props.history.push(path);
  }


  onAddAttendee = e => {
    let path = "/comprador";
    this.props.history.push(path);
  }

  onResendInvitation = e => {
    const { user } = this.props.auth;
    ReSendInvitation(user.id)
      .then(res => materialize.toast({ html: this.locale.dashboard_compradorlanding_toast_success }))
      .catch(err => materialize.toast({ html: this.locale.dashboard_compradorlanding_toast_failure }));
  }


  render() {
    const { user } = this.props.auth;

    return (
      <div class="col s12 m12 l8 container center contentaligned valign-wrapper overflowDiv">
        <div class="col s12 m12 l10 center contentaligned valign-wrapper">
          <div>
            <div className="font-face-mb titulo">
              <h3>
                {this.locale.dashboard_compradorlanding_label_hello} {user.name.split(" ")[0]}
              </h3>
            </div>
            <div className="subTitulo">
              <h5>
                {this.state.userRegistered === false ?
                  <>
                    {this.locale.dashboard_dashboard_label_message_success}{" "}
                    {this.locale.auth_landingsuccess_label_app_name}👏
                  </>
                  :
                  <>
                    {this.state.categoria === "COMPRADOR" ?
                      <>
                        {this.locale.dashboard_dashboard_label_message_registryconfirmation} 👏
                      </>
                      :
                      <>
                        {this.locale.dashboard_dashboard_label_message_registryconfirmation_non_buyer}{" "}<b>{this.getCategoryDesc(this.state.categoria)}</b> 👏
                      </>
                    }
                  </>
                }
              </h5>
            </div>
            <br />
            <br />
            {this.state.userRegistered === true ?
              <>
                {this.state.categoria === "COMPRADOR" ?
                  <>
                    <div className="font-face-mb titulo">
                      <h4>
                        {this.locale.dashboard_dashboard_button_updatedataregister}
                      </h4>
                    </div>
                    <div className="subTitulo">
                      <h6>
                        {this.locale.dashboard_dashboard_modificar_informacion}
                      </h6>
                    </div>
                    <div className="col s12 m12 l12">
                      <button
                        style={{
                          width: "300px",
                          borderRadius: "3px",
                          letterSpacing: "1.5px",
                          marginTop: "1rem"
                        }}
                        onClick={this.onUpdateAttendee}
                        className="btn-large hoverable buttonBackgroundColor font-face-msb"
                      >
                        {this.locale.dashboard_dashboard_button_updatedataregister}
                      </button>
                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                  :
                  <>
                  </>
                }

                <div className="font-face-mb titulo">
                  <h4>
                    {this.locale.dashboard_dashboard_button_resendinvitation}
                  </h4>
                </div>
                <div className="subTitulo">
                  <h6>
                    {this.locale.dashboard_dashboard_reenviar_invitacion}
                  </h6>
                </div>
                <div className="col s12 m12 l12">
                  <button
                    style={{
                      width: "300px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem"
                    }}
                    onClick={this.onResendInvitation}
                    className="btn-large hoverable buttonBackgroundColor font-face-msb"
                  >
                    {this.locale.dashboard_dashboard_button_resendinvitation}
                  </button>
                </div>
                <div className="col s12 m12 l12">
                  <button
                    style={{
                      width: "300px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem"
                    }}
                    onClick={this.onGetQR}
                    className="btn-large hoverable buttonBackgroundColor font-face-msb"
                  >
                    {this.locale.dashboard_dashboard_button_qr}
                  </button>
                </div>                
              </>
              :
              <>
                <div className="font-face-mb titulo">
                  <h4>
                    {this.locale.dashboard_dashboard_button_register}
                  </h4>
                </div>
                <div className="subTitulo">
                  <h6>
                    {this.locale.dashboard_dashboard_message}
                  </h6>
                </div>
                <div className="col s12 m12 l12">
                  <button
                    style={{
                      width: "350px",
                      borderRadius: "3px",
                      letterSpacing: "1.5px",
                      marginTop: "1rem"
                    }}
                    onClick={this.onAddAttendee}
                    className="btn-large hoverable buttonBackgroundColor font-face-msb"
                  >
                    {this.locale.dashboard_dashboard_button_register}
                  </button>
                </div>
              </>
            }
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="col s12 m12 l12">
              <button
                style={{
                  width: "220px",
                  borderRadius: "3px",
                  letterSpacing: "1.5px",
                  marginTop: "1rem"
                }}
                onClick={this.onLogoutClick}
                className="btn hoverable buttonSoporteBackgroundColor font-face-msb"
              >
                {this.locale.dashboard_dashboard_button_logout}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(Dashboard));
