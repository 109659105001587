import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { passwordUserReset, userDetails } from "../../actions/authActions";
import classnames from "classnames";
import queryString from 'query-string'
import Localization from "../localization/localization";


class PasswordReset extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      email2: "",
      password: "",
      password2: "",
      errors: {}
    };
    
    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language")); 
  }

  componentDidMount() {
    const usuarioParsed = queryString.parse(this.props.location.search);
    this.getUser(usuarioParsed.id);
  }

  getUser = (usuarioId) => {
        userDetails(usuarioId)
        .then(res => this.setState({name:res.data.name, email:res.data.email}))
        .catch(err => {
            console.log(err);
            return null;
        });
 };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value.trim() });
  };

  onSubmit = e => {
    e.preventDefault();

    let language = localStorage.getItem("exmi_language");
    if (language == null)
      language = "es";

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      email2: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      locale: language
    };

    this.props.passwordUserReset(newUser, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div>
          <div className="col s12 m12 l6 container contentaligned">
            <Link to="/" className="btn-flat waves-effect">
              <i className="material-icons left">keyboard_backspace</i> 
              {this.locale.auth_passwordreset_label_backtohome}
            </Link>
            <div className="col s12" style={{ paddingLeft: "11.250px" }}>
              <h4>
                <b>{this.locale.auth_passwordreset_label_formtitle}</b>
              </h4>
              <p className="grey-text text-darken-1">
              [<b>{this.locale.auth_passwordreset_label_usuario}</b> {this.state.name} - <b>{this.locale.auth_passwordreset_label_email}</b> {this.state.email}]
              </p>
            </div>
            <form noValidate onSubmit={this.onSubmit}>
              <div className="input-field col s12">
                <input
                  autoFocus
                  onChange={this.onChange}
                  value={this.state.password}
                  error={errors.password}
                  id="password"
                  type="password"
                   className={classnames("font-face-msb", {
                    invalid: errors.password
                  })}
                />
                <label htmlFor="password">{this.locale.auth_passwordreset_label_password}</label>
                <span className="red-text">{errors.password}</span>
              </div>
              <div className="input-field col s12">
                <input
                  onChange={this.onChange}
                  value={this.state.password2}
                  error={errors.password2}
                  id="password2"
                  type="password"
                   className={classnames("font-face-msb", {
                    invalid: errors.password2
                  })}
                />
                <label htmlFor="password2">{this.locale.auth_passwordreset_label_passwordconfirmation}</label>
                <span className="red-text">{errors.password2}</span>
              </div>
              <div className="col s12" style={{ paddingLeft: "11.250px" }}>
                <button
                  style={{
                    width: "150px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  type="submit"
                  className="btn-large hoverable buttonBackgroundColor font-face-msb"
                >
                  {this.locale.auth_passwordreset_button_confirmation}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

PasswordReset.propTypes = {
  passwordUserReset: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { passwordUserReset}
)(withRouter(PasswordReset));
