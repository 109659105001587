import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { getAttendeeByAttendeeId, ReSendInvitationByAttendeeId } from "../../actions/attendeeActions"
import queryString from 'query-string'
import { Link, withRouter } from "react-router-dom";
import materialize from "materialize-css";
import Localization from "../localization/localization";
//import ReactPixel from 'react-facebook-pixel';


class InvitadoLanding extends Component {

  constructor(props) {
    super(props);

    /*
    ReactPixel.init('869801026716151', {}, { debug: false, autoConfig: false });
    ReactPixel.pageView();
    ReactPixel.fbq('track', 'PageView');
    */


    this.state = {
      name: "",
      email: "",
    };


    this.locale = Localization.getLocalizationInstance(localStorage.getItem("exmi_language"));
  }

  componentDidMount() {
    const params = queryString.parse(this.props.location.search)

    console.log(params.id);

    getAttendeeByAttendeeId(params.id)
      .then(res => this.setState({ name: res.data.nombres, email: res.data.email }))
      .catch(err => {
        console.log(err);
        return null;
      });
  }


  onResendInvitation = e => {
    const params = queryString.parse(this.props.location.search)
    ReSendInvitationByAttendeeId(params.id)
      .then(res => materialize.toast({ html: this.locale.dashboard_compradorlanding_toast_success }))
      .catch(err => materialize.toast({ html: this.locale.dashboard_compradorlanding_toast_failure }));
  }


  render() {
    return (
      <div class="col s12 m12 l8 container">
        <Link to="/" className="btn-flat waves-effect">
          <i className="material-icons left">keyboard_backspace</i> {this.locale.dashboard_comprador_label_backtohome}
        </Link>
        <div class="col s12 l10 offset-l1 center contentaligned valign-wrapper">
          <div>
            <div className="row">
              <div className="landing-copy col s12 m12 l12 center-align">
                <h4 className="font-face-msb">
                  {this.locale.dashboard_compradorlanding_label_hello} {this.state.name.split(" ")[0]}
                  <p className="flow-text grey-text text-darken-1">
                    {this.locale.dashboard_dashboard_label_message_registryconfirmation} 👏
                  </p>
                </h4>
              </div>
              <div className="landing-copy col s12 m12 l12 center-align">
                <button
                  style={{
                    width: "350px",
                    borderRadius: "3px",
                    letterSpacing: "1.5px",
                    marginTop: "1rem"
                  }}
                  onClick={this.onResendInvitation}
                  className="btn-large hoverable buttonBackgroundColor font-face-msb"
                >
                  {this.locale.dashboard_dashboard_button_resendinvitation}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InvitadoLanding.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(InvitadoLanding));
